<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col v-if="apps.length > 1" class="py-0">
              <v-select
                v-model="appSelected"
                :items="apps"
                label="Aplicación móvil"
                item-text="value"
                item-value="id"
                outlined
                dense
                clearable
              >
              </v-select>
            </v-col>
            <v-col v-if="isNewModal" class="py-0">
              <v-select
                v-model="tipoEnlace"
                :items="tipoEnlaceCombo"
                label="Enlace para"
                item-text="value"
                item-value="id"
                outlined
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-0">
              <v-text-field
                type="number"
                outlined
                dense
                label="Orden"
                v-model="order"
                :rules="rules.positiveNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="9" v-if="!hidden" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Título"
                v-model.trim="titulo"
                :rules="rules.required.concat([rules.requiredTrim(titulo)])"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="!hidden" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Subtítulo"
                v-model.trim="subtitulo"
                :rules="rules.required.concat([rules.requiredTrim(subtitulo)])"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="tipoEnlace == 2" cols="12" class="py-0">
              <v-text-field
                type="number"
                outlined
                dense
                v-model="telefono"
                onkeydown="return (event.keyCode !== 69 && 
                        event.keyCode !== 109 && 
                        event.keyCode !== 107 &&
                        event.keyCode !== 188 &&
                        event.keyCode !== 190)"
                label="Teléfono"
                hint="Código de área sin el 0 y número de teléfono sin el 15. Ejemplo: 3513728195"
                persistent-hint
                :rules="rules.telephoneRequired"
              ></v-text-field>
            </v-col>
            <v-col v-if="tipoEnlace == 1" cols="12" class="py-0">
              <v-text-field
                type="number"
                outlined
                dense
                v-model="telefono"
                label="Teléfono"
                onkeydown="return (event.keyCode !== 69 && 
                        event.keyCode !== 109 && 
                        event.keyCode !== 107 &&
                        event.keyCode !== 188 &&
                        event.keyCode !== 190)"
                :rules="rules.telephoneRequired"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="tipoEnlace == 2" cols="12" md="12" class="py-0">
              <v-textarea
                name="input-3-4"
                outlined
                dense
                label="Texto"
                v-model="texto"
                :rules="rules.counter"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="tipoEnlace == 3" cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Dirección de correo"
                v-model="mail"
                :rules="rules.email.concat(rules.required)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="tipoEnlace == 3" cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="Asunto"
                v-model="asunto"
                :rules="rules.counter"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="tipoEnlace == 3" cols="12" md="12" class="py-0">
              <v-textarea
                name="input-7-4"
                outlined
                dense
                label="Cuerpo"
                v-model="cuerpo"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="tipoEnlace == 4" cols="12" md="12" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                label="URL"
                v-model="url"
                hint="Formato de URL: http://gecros.com"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal">
          Cancelar
        </v-btn>
        <v-btn type="submit" :disabled="!isValid" form="form" color="primary">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditEnlaceUtil",
  props: ["enlaceId"],
  data() {
    return {
      title: "Editar enlace útil",
      isNewModal: false,
      isValid: false,
      modalOpen: false,
      tipoEnlace: 1,
      texto: "",
      titulo: "",
      order: 0,
      hidden: false,
      tipoEnlaceCombo: [],
      enlace: {},
      mail: "",
      cuerpo: "",
      asunto: "",
      subtitulo: "",
      url: "",
      urlAndroid: "",
      telefono: "",
      rules: rules,
      apps: [],
      appSelected: null
    };
  },
  created() {
    this.setApps();
    if (this.enlaceId != null) this.getEnlace(this.enlaceId);
    else this.newEnlace();
  },
  mounted() {
    this.getComboTiposEnlace();
  },
  methods: {
    ...mapActions({
      getEnlaceById: "appBenef/getEnlaceById",
      updateEnlace: "appBenef/updateEnlace",
      generateEnlace: "appBenef/generateEnlace",
      getCombos: "appBenef/getCombos",
      getApps: "appBenef/getApps"
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    newEnlace() {
      this.title = "Nuevo enlace útil";
      this.isNewModal = true;
    },
    async setApps() {
      const response = await this.getApps();
      this.apps = response;
    },
    // async saveChanges() {
    //   if (!this.isValid) return;
    //   const datos = {
    //     id: this.enlaceId,
    //     title: this.titulo,
    //     subtitle: this.subtitulo,
    //     iconType: typeof this.tipoEnlace === "undefined" ? -1 : this.tipoEnlace,
    //     email: this.mail,
    //     url: this.url,
    //     messaggeText: this.texto || "",
    //     phone: this.telefono,
    //     asunto: this.asunto,
    //     cuerpo: this.cuerpo,
    //     order: this.order,
    //     appId: this.appSelected
    //   };
    //   try {
    //     const response = await this.updateEnlace(datos);
    //     this.enlacesUtiles = response.enlaces;
    //     this.loading = false;
    //   } catch {
    //     this.loading = false;
    //   }
    //   this.closeModal();
    //   this.$bus.$emit("refreshTable", true);
    // },
    async saveEdit() {
      if (this.isNewModal) {
        this.loading = true;
        let bodyParams = {
          title: this.titulo || "",
          subTitle: this.subtitulo || "",
          iconType: this.tipoEnlace,
          messaggeText: this.texto || "",
          url: this.url || "",
          phone: this.telefono || "",
          email: this.mail || "",
          asunto: this.asunto || "",
          cuerpo: this.cuerpo || "",
          order: this.order,
          appId: this.appSelected
        };
        try {
          const response = await this.generateEnlace({ bodyParams });
          this.enlacesUtiles = response.enlaces;
          this.loading = false;
        } catch {
          this.loading = false;
        }
        this.closeModal();
      } else {
        let datos = {
          id: this.enlaceId,
          title: this.titulo,
          subtitle: this.subtitulo,
          iconType:
            typeof this.tipoEnlace === "undefined" ? -1 : this.tipoEnlace,
          email: this.mail,
          url: this.url,
          messaggeText: this.texto || "",
          phone: this.telefono,
          asunto: this.asunto,
          cuerpo: this.cuerpo,
          order: this.order,
          appId: this.appSelected
        };
        try {
          const response = await this.updateEnlace(datos);
          this.enlacesUtiles = response.enlaces;
          this.loading = false;
        } catch {
          this.loading = false;
        }
        this.closeModal();
      }
    },
    async getEnlace(enlaceId) {
      const res = await this.getEnlaceById({ enlaceId });
      this.titulo = res.title;
      this.url = res.url;
      this.urlAndroid = res.urlAndroid;
      this.subtitulo = res.subTitle;
      this.tipoEnlace = res.iconType;
      this.tipoEnlace == 2
        ? this.getFieldsFromUrl(this.urlAndroid)
        : this.getFieldsFromUrl(this.url);
      this.order = res.order;
      this.appSelected = res.appId;
    },
    async getComboTiposEnlace() {
      const response = await this.getCombos();
      this.tipoEnlaceCombo = response;
    },

    getFieldsFromUrl(url) {
      let telefono = "";
      let mail = "";
      let subject = "";
      let body = "";
      let text = "";
      if (this.tipoEnlace == 1 && url != null) {
        for (let i = 0; i < url.length; i++) {
          if (
            url.charAt(i) == ":" &&
            url.charAt(i - 1) == "l" &&
            url.charAt(i - 2) == "e"
          ) {
            for (let j = i + 1; j < url.length; j++) {
              url.charAt(j) != "+" || url.charAt(j) != " "
                ? (telefono += url.charAt(j))
                : (telefono = telefono);
            }
            break;
          }
        }
        this.telefono = telefono;
      }
      if (this.tipoEnlace == 2 && url != null) {
        for (let i = 0; i < url.length; i++) {
          if (
            url.charAt(i) == "4" &&
            url.charAt(i - 1) == "5" &&
            url.charAt(i - 2) == "+"
          ) {
            for (let j = i + 1; url.charAt(j) != "&"; j++) {
              url.charAt(j) != "+" || url.charAt(j) != " "
                ? (telefono += url.charAt(j))
                : (telefono = telefono);
            }
            break;
          }
        }
        this.telefono = telefono.trim();

        for (let i = 0; i < url.length; i++) {
          if (url.charAt(i) == "=" && url.charAt(i - 1) == "t") {
            for (let j = i + 1; j < url.length; j++) {
              text += url.charAt(j);
            }

            break;
          }
        }
        this.texto = text;
      }

      if (this.tipoEnlace == 3 && url != null) {
        for (let i = 0; i < url.length; i++) {
          if (
            url.charAt(i) == ":" &&
            url.charAt(i - 1) == "o" &&
            url.charAt(i - 2) == "t"
          ) {
            for (let j = i + 1; url.charAt(j) != "?"; j++) {
              mail += url.charAt(j);
            }
          }

          if (
            url.charAt(i) == "=" &&
            url.charAt(i - 1) == "t" &&
            url.charAt(i - 2) == "c"
          ) {
            for (let j = i + 1; url.charAt(j) != "&"; j++) {
              subject += url.charAt(j);
            }
          }
          if (
            url.charAt(i) == "=" &&
            url.charAt(i - 1) == "y" &&
            url.charAt(i - 2) == "d"
          ) {
            for (let j = i + 1; j < url.length; j++) {
              body += url.charAt(j);
            }
            this.mail = mail;
            this.asunto = subject;
            this.cuerpo = body;
            break;
          }
        }
      }
    }

    // async generarEnlace() {
    //   this.loading = true;
    //   const bodyParams = {
    //     title: this.titulo || "",
    //     subTitle: this.subtitulo || "",
    //     iconType: this.tipoEnlace,
    //     messaggeText: this.texto || "",
    //     url: this.url || "",
    //     phone: this.telefono || "",
    //     email: this.mail || "",
    //     asunto: this.asunto || "",
    //     cuerpo: this.cuerpo || "",
    //     order: this.order,
    //     appId: this.appSelected
    //   };
    //   try {
    //     const response = await this.generateEnlace({ bodyParams });
    //     this.enlacesUtiles = response.enlaces;
    //     this.loading = false;
    //   } catch {
    //     this.loading = false;
    //   }
    //   this.closeModal();
    //   this.$bus.$emit("refreshTable", true);
    // }
  }
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
