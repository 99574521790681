<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            id="enlacesTable"
            :headers="headers"
            :header-props="{ sortIcon: null }"
            class="elevation-1"
            :items="enlacesUtiles"
            :loading="loading"
            :search="search"
          >
            <template v-slot:top >
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                    @click="openModal(item.enlaceId)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    size="20"
                    @click="openDeleteItem(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="500px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditEnlaceUtil
        :enlaceId="enlaceId"
        @closeAndReload="closeAndReload"
      ></EditEnlaceUtil>
    </v-dialog>
    <DeleteDialog
      :textDelete="textDelete"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteEnlace()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import EditEnlaceUtil from "@/components/modules/appBenef/configuracion/EditEnlaceUtil.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "EnlacesUtilesApp",
  components: {
    PageHeader,
    EditEnlaceUtil,
    DeleteDialog,
    GoBackBtn,
    Ayuda
  },
  data() {
    return {
      itemToDelete: {},
      headers: [
        { text: "Aplicación", align: "start", value: "appNom" },
        { text: "Titulo", value: "title" },
        { text: "Subtitulo", value: "subTitle" },
        { text: "Tipo", value: "iconType" },
        { text: "Acciones", value: "actions", align: "end" }
      ],
      title: enums.titles.ENLACES_UTILES,
      optionCode: enums.webSiteOptions.ENLACES_UTILES,
      showHelp: false,
      showExpand: false,
      showIcon: true,
      isFormValid: true,
      loading: false,
      currentPage: 0,
      pageSize: 5,
      textDelete: null,
      enlacesUtiles: [],
      routeToGo: "ConfiguracionAppBenef",
      openModalEdit: false,
      openModalDelete: false,
      enlaceId: 0,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      openDeleteDialog: false,
      allowedActions: null,
      canEdit: false,
      canDelete: false,
      canCreate: false,
      search: "",
      searchIcon: enums.icons.SEARCH
    };
  },

  computed: {
    ...mapGetters({
      listModulos: "user/modulos"
    })
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loading = false;
    this.getEnlacesUtiles();
  },

  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
    this.getEnlacesUtiles();
    this.options = { page: 1, itemsPerPage: 5 };
    this.currentPage = this.options.page;
  },

  methods: {
    ...mapActions({
      getEnlacesUtilesInfoTable: "appBenef/getEnlacesUtilesInfoTable",
      deleteEnlaceById: "appBenef/deleteEnlaceById"
    }),

    openModal(enlaceId) {
      this.openModalEdit = true;
      this.enlaceId = enlaceId;
    },

    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_ENLACES_UTILES:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ENLACES_UTILES:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_ENLACES_UTILES:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },

    openDeleteItem(item) {
      this.itemToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteEnlace() {
      const res = await this.deleteEnlaceById(this.itemToDelete);
      this.getEnlacesUtiles();
    },

    async getEnlacesUtiles() {
      const response = await this.getEnlacesUtilesInfoTable();
      this.enlacesUtiles = response;
    },
    closeModal() {
      this.openModalEdit = false;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.getEnlacesUtiles();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
